var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/tmp/build_51e88692/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["TutorCruncher/static/js2/templates/main-menu.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["data_dict"], 
[], 
function (l_data_dict, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data_dict", l_data_dict);
var t_2 = "";if(l_data_dict) {
frame = frame.push();
var t_5 = env.getFilter("dictsort").call(context, l_data_dict);
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_3;
if(runtime.isArray(t_5)) {
var t_4 = t_5.length;
for(t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3][0];
frame.set("[object Object]", t_5[t_3][0]);
var t_7 = t_5[t_3][1];
frame.set("[object Object]", t_5[t_3][1]);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
if(t_7) {
t_2 += " data-";
t_2 += runtime.suppressValue(t_6, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_7, env.opts.autoescape);
t_2 += "\"";
;
}
;
}
} else {
t_3 = -1;
var t_4 = runtime.keys(t_5).length;
for(var t_8 in t_5) {
t_3++;
var t_9 = t_5[t_8];
frame.set("k", t_8);
frame.set("v", t_9);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
if(t_9) {
t_2 += " data-";
t_2 += runtime.suppressValue(t_8, env.opts.autoescape);
t_2 += "=\"";
t_2 += runtime.suppressValue(t_9, env.opts.autoescape);
t_2 += "\"";
;
}
;
}
}
}
frame = frame.pop();
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("data_attrs");
context.setVariable("data_attrs", macro_t_1);
var macro_t_10 = runtime.makeMacro(
["item", "depth"], 
[], 
function (l_item, l_depth, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("item", l_item);
frame.set("depth", l_depth);
var t_11 = "";t_11 += "\n  <a class=\"menu-item depth_";
t_11 += runtime.suppressValue(l_depth, env.opts.autoescape);
t_11 += " ";
t_11 += runtime.suppressValue(runtime.memberLookup((l_item),"class"), env.opts.autoescape);
t_11 += " ";
t_11 += runtime.suppressValue(((lineno = 9, colno = 78, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [l_item]))?"active":""), env.opts.autoescape);
t_11 += "\"\n       id=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((l_item),"menu_id"), env.opts.autoescape);
t_11 += "\"\n       href=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((l_item),"url"), env.opts.autoescape);
t_11 += "\"\n       ";
if(runtime.memberLookup((l_item),"newtab")) {
t_11 += "target=\"_blank\"";
;
}
t_11 += "\n       ";
t_11 += runtime.suppressValue((lineno = 13, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "data_attrs"), "data_attrs", context, [runtime.memberLookup((l_item),"data")])), env.opts.autoescape);
t_11 += "\n    >";
if(runtime.memberLookup((l_item),"icon")) {
t_11 += "<span class=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((l_item),"icon"), env.opts.autoescape);
t_11 += " fa-fw\"></span>&nbsp;";
;
}
t_11 += runtime.suppressValue(runtime.memberLookup((l_item),"name"), env.opts.autoescape);
if(runtime.memberLookup((l_item),"sub_button")) {
t_11 += "\n      <object>\n        <a class=\"float-right sub-button ";
t_11 += runtime.suppressValue(((lineno = 21, colno = 66, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [l_item]))?"btn-active":""), env.opts.autoescape);
t_11 += "\" href=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_item),"sub_button")),"url"), env.opts.autoescape);
t_11 += "\" ";
if(runtime.memberLookup((runtime.memberLookup((l_item),"sub_button")),"tooltip")) {
t_11 += "data-toggle=\"tooltip\" data-placement=\"top\" title=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_item),"sub_button")),"tooltip"), env.opts.autoescape);
t_11 += "\"";
;
}
t_11 += ">\n          <span class=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_item),"sub_button")),"icon"), env.opts.autoescape);
t_11 += " fa-fw\"></span>\n        </a>\n      </object>\n    ";
;
}
t_11 += "\n    ";
if(runtime.memberLookup((l_item),"new")) {
t_11 += "\n      <span class=\"bg-success text-light badge-pill float-right pr-2\"><span class='small'>new</span></span>\n    ";
;
}
t_11 += "\n  </a>";
;
frame = callerFrame;
return new runtime.SafeString(t_11);
});
context.addExport("menu_item");
context.setVariable("menu_item", macro_t_10);
frame = frame.push();
var t_14 = (lineno = 32, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "visible"), "visible", context, [runtime.contextOrFrameLookup(context, frame, "data")]));
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("item", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
if(runtime.inOperator("submenu",t_15)) {
var t_16;
t_16 = (lineno = 34, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "subactive"), "subactive", context, [t_15]));
frame.set("subactive_", t_16, true);
if(frame.topLevel) {
context.setVariable("subactive_", t_16);
}
if(frame.topLevel) {
context.addExport("subactive_", t_16);
}
output += "\n    <div class=\"card";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "subactive_")?" inside":""), env.opts.autoescape);
output += "\">\n      <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"url"), env.opts.autoescape);
output += "\"\n       class=\"menu-item toggle ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"class"), env.opts.autoescape);
output += "\"\n       ";
if(runtime.memberLookup((t_15),"newtab")) {
output += "target=\"_blank\"";
;
}
output += "\n       data-toggle=\"collapse\">\n        <span class=\"fas fa-chevron-right\"></span>";
if(runtime.memberLookup((t_15),"icon")) {
output += "\n          <span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"icon"), env.opts.autoescape);
output += " fa-fw\"></span>\n        ";
;
}
output += runtime.suppressValue(runtime.memberLookup((t_15),"name"), env.opts.autoescape);
output += "\n      </a>\n      <div id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"menu_id"), env.opts.autoescape);
output += "\" class=\"collapse ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "subactive_")?"show":""), env.opts.autoescape);
output += "\" data-item=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"menu_id"), env.opts.autoescape);
output += "\" data-parent=\"#menu_accordion\">\n        <div id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"menu_id"), env.opts.autoescape);
output += "-parent\">";
frame = frame.push();
var t_19 = (lineno = 48, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "visible"), "visible", context, [runtime.memberLookup((t_15),"submenu")]));
if(t_19) {t_19 = runtime.fromIterator(t_19);
var t_18 = t_19.length;
for(var t_17=0; t_17 < t_19.length; t_17++) {
var t_20 = t_19[t_17];
frame.set("item_", t_20);
frame.set("loop.index", t_17 + 1);
frame.set("loop.index0", t_17);
frame.set("loop.revindex", t_18 - t_17);
frame.set("loop.revindex0", t_18 - t_17 - 1);
frame.set("loop.first", t_17 === 0);
frame.set("loop.last", t_17 === t_18 - 1);
frame.set("loop.length", t_18);
output += runtime.suppressValue((lineno = 49, colno = 24, runtime.callWrap(macro_t_10, "menu_item", context, [t_20,2])), env.opts.autoescape);
;
}
}
frame = frame.pop();
output += "</div>\n      </div>\n    </div>\n  ";
;
}
else {
output += "\n    ";
output += runtime.suppressValue((lineno = 55, colno = 16, runtime.callWrap(macro_t_10, "menu_item", context, [t_15,1])), env.opts.autoescape);
;
}
;
}
}
frame = frame.pop();
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["TutorCruncher/static/js2/templates/main-menu.njk"] , dependencies)